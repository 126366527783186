
import { Component, Vue } from 'vue-property-decorator'
import { phone } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import { FormInfo } from '@/types/personnel'
import { ProjectDetail } from '../../types/project'

@Component
export default class PersonnelAdd extends Vue {
  private info: FormInfo = {
    userName: '',
    mobile: '',
    roleId: '',
    deptId: '',
    loginId: ''
  }

  private rules ={
    userName: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
    deptId: [{ required: true, message: '请选择所属组织', trigger: ['change'] }],
    roleId: [{ required: true, message: '请选择角色', trigger: ['change'] }],
    mobile: [
      { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
      { validator: phone, trigger: ['blur', 'change'] }
    ],
    loginId: [{ required: true, message: '请输入登录账号', trigger: ['blur', 'change'] }]
  }

  private deptId = ''
  private deptList = []
  private roleList: Array<{roleId: string; roleName: string; dataPermission: string}> = []
  private dataPermission = ''

  private prop = {
    expandTrigger: 'hover',
    value: 'deptId',
    label: 'deptName',
    children: 'childNode',
    emitPath: false
  }

  private submitShow = false
  private projectCheckList = []

  get id () {
    return this.$route.params.id
  }

  created () {
    this.deptGet()
    this.roleListGet()
    this.getProjectCheckList()
    if (this.id) {
      this.dataGet()
    }
  }

  deptGet () {
    this.$axios.get(this.$apis.department.departmentList).then((res) => {
      this.deptList = res || []
    })
  }

  roleListGet () {
    this.$axios.get(this.$apis.common.getRoleList).then((res) => {
      this.roleList = res || []
    })
  }

  getProjectCheckList () {
    this.$axios.get<ProjectDetail>(this.$apis.project.selectYhProjectByList).then(res => {
      this.projectCheckList = res.map((item: ProjectDetail) => {
        return {
          checked: false,
          projectName: item.projectName,
          projectId: item.projectId
        }
      })
    }).finally(() => {
      if (this.id) {
        this.projectCurrent()
      }
    })
  }

  projectCurrent () {
    this.$axios.get<ProjectDetail>(this.$apis.project.selectYhProjectByList, {
      userId: this.id
    }).then(res => {
      res.forEach((item: ProjectDetail) => {
        this.projectCheckList.forEach((items: any) => {
          if (item.projectId === items.projectId) {
            items.checked = true
          }
        })
      })
    })
  }

  userChange (id: any) {
    const row = this.roleList.find(item => item.roleId === id)
    if (row) {
      this.dataPermission = row.dataPermission
    }
  }

  dataGet () {
    this.$axios.get(this.$apis.personnel.personnelDetail, {
      userId: this.id
    }).then((res) => {
      this.info = {
        userName: res.userName || '',
        mobile: res.mobile || '',
        roleId: res.roleId || '',
        deptId: res.deptId || '',
        loginId: res.loginId || '',
        userId: res.userId || ''
      }
      this.userChange(this.info.roleId)
      this.deptId = res.deptId
    })
  }

  deptChange () {
    this.info.deptId = this.deptId ? this.deptId : ''
  }

  resetPassword () {
    this.$axios.post(this.$apis.personnel.resetPassword, {
      userId: this.id
    }).then(() => {
      this.$message.success('重置成功')
    }).catch((err) => {
      this.$message(err)
    })
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.id
          ? this.$apis.personnel.personnelUpdate
          : this.$apis.personnel.personnelAdd
        this.$axios.post(url, this.info).then((res) => {
          const userId = this.id ? this.id : res.userId
          if (this.dataPermission !== '1') {
            this.bindProject(userId)
          } else {
            this.$message.success('保存成功')
            this.$router.push({ name: 'department' })
          }
        }).catch(() => {
          this.submitShow = false
        })
      }
    })
  }

  bindProject (userId: string) {
    const projectIdList: Array<string> = []
    this.projectCheckList.forEach((item: any) => {
      if (item.checked) {
        projectIdList.push(item.projectId)
      }
    })
    this.$axios.post(this.$apis.personnel.insertYhProjectByUserId, {
      userId: userId,
      projectIdList: projectIdList
    }).then(() => {
      this.$message.success('保存成功')
      this.$router.push({ name: 'department' })
    }).catch(() => {
      this.submitShow = false
    })
  }
}
